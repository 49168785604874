import React from 'react'

const OurMenu = () => {
    return (
        <div>
            <h1>Our Menu</h1>
            <p>Delicious dishes made from the freshest ingredients.</p>
        </div>
    )
}

export default OurMenu
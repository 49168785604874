import React from 'react'
import Home from './sections/Home'
import About from './sections/About'
import Contact from './sections/Contact'
import OurMenu from './sections/OurMenu'
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
  mainContent: {
    flex: 1,
    padding: '2rem',
    paddingTop: '4rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#333',
    color: 'white',
    textAlign: 'center',
  },
  section: {
    minHeight: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '50vh',
    color: 'white',
    textAlign: 'center',
  }

});

const MainContent = () => {
  const styles = useStyles();

  return (
    <main className={styles.mainContent}>
      <section className={styles.section} id="home">
        <Home />
      </section>
      <section className={styles.section} id="menu">
        <OurMenu />
      </section>
      <section className={styles.section} id="about">
        <About />
      </section>
      <section className={styles.section} id="contact">
        <Contact />
      </section>
    </main>
  )
}

export default MainContent
import React from 'react'

const About = () => {
    return (
        <div className="content">
            <h1>About Us</h1>
            <p>Learn more about our restaurant.</p>
        </div>
    )
}

export default About

import React, { useState } from 'react';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
    header: {
        backgroundColor: '#333',
        color: 'white',
        textAlign: 'center',
        padding: '1rem',
        position: 'fixed',
        width: '100%',
        top: 0,
        left: 0,
        zIndex: 1000
    },
    nav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    hamburger: {
        display: 'none',
        cursor: 'pointer',
        fontSize: '2rem',
        '@media (max-width: 600px)': {
            display: 'block'
        }
    },
    navLinks: {
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        justifyContent: 'center',
        '@media (max-width: 600px)': {
            display: 'none',
            flexDirection: 'column',
            width: '100%',
            textAlign: 'center'
        },
        '&.open': {
            '@media (max-width: 600px)': {
                display: 'flex'
            }
        },


    },
    navLinkItem: {
        margin: '0 1rem',
        '@media (max-width: 600px)': {
            margin: '1rem 0'
        }
    },

    navLinkAnchor: {
        color: 'white',
        textDecoration: 'none'
    }


});

const Header = () => {
    const styles = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className={styles.header}>
            <nav className={styles.nav}>
                <div className={styles.hamburger} onClick={toggleMenu}>
                    &#9776;
                </div>
                <ul className={`${styles.navLinks} ${isOpen ? 'open' : ''}`}>
                    <li className={styles.navLinkItem}>
                        <a className={styles.navLinkAnchor} href="#home">Home</a></li>
                    <li className={styles.navLinkItem}>
                        <a className={styles.navLinkAnchor} href="#about">About</a></li>
                    <li className={styles.navLinkItem}>
                        <a className={styles.navLinkAnchor} href="#contact">Contact</a></li>
                </ul>
            </nav>
        </header>
    );
};

export default Header;
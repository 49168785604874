import React from 'react'

const Contact = () => {
    return (
        <div>
            <h1>Contact Us</h1>
            <p>This is the contact section. Here you can add contact information or a contact form.</p>
        </div>
    )
}

export default Contact
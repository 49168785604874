import React from 'react'
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({
    footer: {
        backgroundColor: '#333',
        color: 'white',
        textAlign: 'center',
        padding: '1rem',
        position: 'fixed',
        width: '100%',
        bottom: 0,
        left: 0,
        zIndex: 1000
    },
    footerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        '@media (max-width: 600px)': {
            flexDirection: 'column'
        },
    },
    footerNav: {
        '& ul': {
            listStyle: 'none',
            padding: 0,
            display: 'flex',
            '@media (max-width: 600px)': {
                flexDirection: 'column'
            },
            '& li': {
                margin: '0 1rem',
                '& a': {
                    color: 'white',
                    textDecoration: 'none'
                },
                '@media (max-width: 600px)': {
                    margin: '0.5rem 0'
                },
            }
        }
    },
    footerSocial: {
        '@media (max-width: 600px)': {
            marginTop: '1rem'
        },
        '& a': {
            color: 'white',
            textDecoration: 'none',
            margin: '0 1rem'
        }
    }

});

const Footer = () => {
    const styles = useStyles();
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContent}>
                <div className={styles.footerNav}>
                    <ul>
                        <li><a href="#privacy">Privacy Policy</a></li>
                        <li><a href="#terms">Terms of Service</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
                <div className={styles.footerSocial}>
                    <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
                    <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
                </div>
            </div>
            <p>&copy; 2023 My SPA</p>
        </footer>)
}

export default Footer